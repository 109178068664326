import { useAppSelector } from '@store/hooks';
import { ETableName } from '@common/utils/enums';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

export const useCrossFiltering = () => {
  const table = useAppSelector(state => state.table);
  const navigate = useNavigate();
  const crossFilter = (value: string, destinationTable: ETableName, Ismodal?: boolean) => {
    const modalParam = Ismodal ? `&modal=true` : `&modal=false`;
    const params = `&filter=${value || ''}&source=${
      table.name || ''
    }&destination=${destinationTable}${modalParam}`;

    navigate(`/${destinationTable}?cross-filter=true${params}`);
  };
  return { crossFilter };
};

export const useDashboardCrossFiltering = () => {
  const navigate = useNavigate();
  const dashboardCrossFilter = (
    value: string,
    destinationTable: ETableName,
    dateRange?: { from: string; to: string },
    vehIds?: string[],
    tripIds?: number[]
  ) => {
    const formattedDate = {
      startDate: '',
      endDate: ''
    };
    if (dateRange) {
      formattedDate.startDate = moment(dateRange.from).startOf('day').toISOString();
      formattedDate.endDate = moment(dateRange.to).endOf('day').toISOString();
    }
    const params = `&searchId=${value || ''}${
      destinationTable === ETableName.TRANSACTIONS && dateRange
        ? `&from=${formattedDate.startDate}&to=${formattedDate.endDate}`
        : ''
    }${vehIds ? `&vehIds=${JSON.stringify(vehIds)}` : ''}${
      destinationTable === ETableName.TRIPS && tripIds ? `&tripIds=${JSON.stringify(tripIds)}` : ''
    } &source=dashboard&destination=${destinationTable}`;

    navigate(`/${destinationTable}?cross-filter=true${params}`);
  };
  return { dashboardCrossFilter };
};
