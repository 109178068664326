/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unsafe-return */


export const formatNumberToUSStandard = (value: number): string => {
  const formattedValue = value.toLocaleString('en-US', {
    maximumFractionDigits: 2
  });
  return formattedValue;
};

export const formatNumberToCurrency = (value: number, currencysymbol: string): string => {
  if (!currencysymbol) {
    const formattedValue = value.toLocaleString('en-US', { maximumFractionDigits: 2 });
    return formattedValue;
  }
  return value < 0
    ? `-${currencysymbol}${Math.abs(value).toLocaleString('en-US', { maximumFractionDigits: 2 })}`
    : currencysymbol + value.toLocaleString('en-US', { maximumFractionDigits: 2 });
};

export const formatNumberToCurrencyNoDecimal = (value: number, currencysymbol: string): string => {
  if (!currencysymbol) {
    const formattedValue = value.toLocaleString('en-US', { maximumFractionDigits: 0 });
    return formattedValue;
  }
  return value < 0
    ? `-${currencysymbol}${Math.abs(value).toLocaleString('en-US', { maximumFractionDigits: 0 })}`
    : currencysymbol + value.toLocaleString('en-US', { maximumFractionDigits: 0 });
};

export const formatMonth = (monthabbrev: string): string => {
  const months: { [key: string]: string } = {
    'jan': 'January',
    'feb': 'February',
    'mar': 'March',
    'apr': 'April',
    'may': 'May',
    'jun': 'June',
    'jul': 'July',
    'aug': 'August',
    'sep': 'September',
    'oct': 'October',
    'nov': 'November',
    'dec': 'December'
  };
  const monthabb:string = (`${monthabbrev}`).toLowerCase();
  return months[monthabb];
};

export const formatCurrency = (
  amount: string,
  ignoreDecimal?: boolean,
  giveAbsoluteNumber?: boolean
): string => {
  let value = Number(amount);

  if (Number.isNaN(value)) {
    return 'Invalid number';
  }

  if (giveAbsoluteNumber) {
    value = Math.abs(value);
  }

  if (ignoreDecimal) {
    const intValue = Math.floor(value);
    return intValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    });
  }

  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
  });
};

export const formatMiles = (miles: string): string => {
  const parsedMiles = parseFloat(miles);
  const roundedMiles = Math.floor(parsedMiles);

  if (Number.isNaN(roundedMiles)) {
    return 'Invalid number';
  }

  return roundedMiles.toLocaleString('en-US');
};

export const formatPercentage = (
  percent: string,
  isAbstract?: boolean,
  giveAbsoluteNumber?: boolean
): string => {
  let value = Number(percent);
  const hasDecimalPlaces = value % 1 !== 0;

  if (giveAbsoluteNumber) {
    value = Math.abs(value);
  }

  if (isAbstract) {
    const isValueAbstract = value > 100;
    if (isValueAbstract) {
      return `>100%`;
    }
  }

  let result = `${value}%`;

  if (hasDecimalPlaces) {
    result = `${value.toFixed(1)}%`;
  }

  return result;
};

export const getOrdinalSuffix = (date: Date): string => {
  const day = new Date(date).getUTCDate();
  const suffixes = ["th", "st", "nd", "rd"];
  const value = day % 100;

  return `${day}${(suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0])}`;
};
