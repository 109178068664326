/* eslint-disable */
import { memo, useEffect, useMemo, useState } from 'react';
import { ChartOptions } from 'chart.js';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import BarChart from '@common/ui/Chart/BarChart/BarChart';
import { Button, Grid, Skeleton, useMediaQuery } from '@mui/material';
import { Repeat } from '@assets/icons';
import { useTheme } from '@mui/material/styles';
import styles from './trip-insights.module.scss';
import useTripInsightsData from './hooks/useTripInsightsData';
import BarChartSkeleton from '../common/common';
import {
  formatMonth,
  formatNumberToCurrency,
  formatNumberToCurrencyNoDecimal,
  formatNumberToUSStandard
} from '@pages/dashboard/common/helper';
import AverageTollPerMile from '../trip-value/average-toll-per-mile/AverageTollPerMile';
import AverageTollPerHour from '../trip-value/average-toll-per-hour/AverageTollPerHour';
import TollTripCount from '../trip-value/toll-trip-count/TollTripCount';
import TollTripCost from '../trip-value/toll-trip-cost/TollTripCost';
import { Plugin } from 'chart.js';
import warningImage from '@assets/images/exclamation-triangle.svg'; // Import the image
import { ITripInsightsProps } from './types';

const TripInsights = ({ selectedMonth }: ITripInsightsProps) => {
  const {
    tripInsightsData,
    tollTripCostData,
    tollTripCountData,
    averageTollPerHourData,
    averageTollPerMileData,
    isLoading,
    showError,
    fetchTripInsightsData
  } = useTripInsightsData(selectedMonth);

  const commonDimension = useMemo(() => {
    return {
      height: '30rem',
      width: '100%'
    };
  }, []);
  const [cardHeight, setCardHeight] = useState('70rem');
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md')); // 1152 px
  const isMd2 = window.innerWidth > 950 && window.innerWidth < 970; /** range between 950 to 970 */
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));
  const titleName = selectedMonth.length > 0 ? selectedMonth.slice(0, -4) + ' Trips' : 'Trip Value';
  const helpLocation = selectedMonth.length > 0 ? 'month_trips' : 'trip_value';

  const customSymbol = useMemo<Plugin<'bar'>>(() => {
    const imageCache = new Map<number, HTMLImageElement>();
    const imagePositions = new Map<number, { x: number; y: number }>();

    return {
      id: 'customSymbol',
      afterDatasetsDraw: chart => {
        const ctx = chart.ctx;
        // Enable high-quality image scaling
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';

        if (chart.data.labels) {
          chart.data.labels.forEach((label, index) => {
            let allZero = true;
            chart.data.datasets.forEach(dataset => {
              let data: any = dataset.data[index];
              if (Number(data.valued_trip_count) || Number(data.novalued_trip_count)) {
                allZero = false;
              }
            });

            if (allZero) {
              const meta = chart.getDatasetMeta(0); // Get meta for the first dataset to access bar coordinates
              const bar = meta.data[index];

              const x = bar.x + 5; // Adjust to center the image
              const y = chart.scales.y.getPixelForValue(0) - 40; // Adjust to position the image above the x-axis

              // Cache the image
              if (!imageCache.has(index)) {
                const image = new Image();
                image.src = warningImage; // Use the imported image
                image.onload = () => {
                  imageCache.set(index, image);
                  imagePositions.set(index, { x, y });
                  ctx.drawImage(image, x, y, 40, 40); // Adjust the size of the image
                };
              } else {
                const cachedImage = imageCache.get(index);
                if (cachedImage) {
                  ctx.drawImage(cachedImage, x, y, 40, 40); // Draw the cached image
                }
              }
            }
          });
        }
      },
      afterDraw: chart => {
        chart.canvas.addEventListener('mousemove', event => {
          const mouseX = event.offsetX;
          const mouseY = event.offsetY;

          let tooltipEl = document.getElementById('custom-tooltip');

          // Create tooltip element if it does not exist
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'custom-tooltip';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
            tooltipEl.style.color = 'white';
            tooltipEl.style.padding = '5px';
            tooltipEl.style.borderRadius = '3px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.opacity = '0';
            document.body.appendChild(tooltipEl);
          }

          // Check if mouse is over any image
          let isTooltipVisible = false;
          imagePositions.forEach((position, index) => {
            const { x, y } = position;

            if (mouseX > x && mouseX < x + 40 && mouseY > y && mouseY < y + 40) {
              if (tooltipEl) {
                // Check if tooltipEl is not null
                tooltipEl.innerHTML = 'No toll bill uploaded.'; // Set the text for the tooltip

                // Position tooltip above the image
                tooltipEl.style.opacity = '1';
                tooltipEl.style.left = event.clientX + 10 + 'px'; // Add some padding to position
                tooltipEl.style.top = event.clientY - 50 + 'px'; // Position above the image (adjust -50 based on your image height and tooltip size)
                isTooltipVisible = true;
              }
            }
          });

          if (!isTooltipVisible && tooltipEl) {
            // Ensure tooltipEl is not null
            tooltipEl.style.opacity = '0';
          }
        });
      }
    };
  }, [warningImage]);

  const [chartOptions, setChartOptions] = useState<ChartOptions<'bar'>>({
    parsing: {
      xAxisKey: 'xaxis' // Add xAxisKey if you want to parse the x values as well
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      autoPadding: true,
      padding: {
        right: 200, // lg : 130 xl : 40 md : 200 sm : 0 xs : 0
        bottom: 80 // lg : 80 xl : 80 md : 80 sm : 0 xs : 0
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        reverse: false,
        align: 'center',
        labels: {
          usePointStyle: true,
          pointStyle: 'rect'
        }
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'top',
        offset: 0,
        textAlign: 'right',
        formatter: (value, context) => {
          let datasets: any = [context.chart.data.datasets[0]]; //take first array item only and convert to array.
          const valuedtotal = datasets.reduce(
            (sum: number, dataset: any) =>
              sum + Number(dataset.data[context.dataIndex]['valued_trip_count']),

            0
          );

          datasets = [context.chart.data.datasets[1]]; //take 2nd array item only and convert to array.
          const novaluedtotal = datasets.reduce(
            (sum: number, dataset: any) =>
              sum + Number(dataset.data[context.dataIndex]['novalued_trip_count']),
            0
          );

          return context.datasetIndex === 0
            ? valuedtotal
              ? valuedtotal
              : ''
            : novaluedtotal
            ? novaluedtotal
            : '';
        }
      },
      // tooltip: {
      //   enabled: true,
      //   // bodyFont: { weight: 'bold'},
      //   callbacks: {
      //     title: function (context) {
      //       return formatMonth(context[0].label);
      //     },
      //     label: function (context) {
      //       const keyprefix = context.datasetIndex === 0 ? 'valued_' : 'novalued_';
      //       const rawdata: any = context.raw !== undefined ? context.raw : {};
      //       const datasetLabel = context.dataset.label || '';

      //       const avgtoll_per_hour = rawdata[keyprefix + 'avgtoll_per_hour'];

      //       const avgtoll_per_mile = rawdata[keyprefix + 'avgtoll_per_mile'];
      //       const tripcount = rawdata[keyprefix + 'trip_count'];
      //       const trip_total = rawdata[keyprefix + 'trip_total'];

      //       const Count = `${Math.floor(Number(tripcount)).toLocaleString()}`;

      //       const trip = `Toll Trip Cost:        ${formatNumberToCurrencyNoDecimal(
      //         Number(trip_total),
      //         '$'
      //       )} (${Count})`;
      //       const per_hour = `Avg Toll per Hour: ${formatNumberToCurrency(
      //         Number(avgtoll_per_hour),
      //         '$'
      //       )}/hour`;
      //       const per_mile = `Avg Toll Per Mile:  ${formatNumberToCurrency(
      //         Number(avgtoll_per_mile),
      //         '$'
      //       )}/mile`;

      //       return [datasetLabel, trip, per_hour, per_mile];
      //     },
      //   }
      // }
      tooltip: {
        enabled: false, // Disable the default tooltip
        external: function (context) {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.backgroundColor = 'rgba(0, 0, 0)';
            tooltipEl.style.color = 'white';
            tooltipEl.style.padding = '10px';
            tooltipEl.style.borderRadius = '5px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.opacity = '1';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          // Set Text
          if (tooltipModel.body) {
            const rawdata = context.tooltip.dataPoints[0].raw;
            const datasetLabel = context.tooltip.dataPoints[0].dataset.label || '';
            const label = context.tooltip.dataPoints[0].label || '';

            const keyprefix =
              context.tooltip.dataPoints[0].datasetIndex === 0 ? 'valued_' : 'novalued_';

            const trip_total = (rawdata as any)[keyprefix + 'trip_total'] || 0;
            const avgtoll_per_hour = (rawdata as any)[keyprefix + 'avgtoll_per_hour'] || 0;
            const avgtoll_per_mile = (rawdata as any)[keyprefix + 'avgtoll_per_mile'] || 0;
            const tripcount = (rawdata as any)[keyprefix + 'trip_count'] || 0;

            const Count = `<b>${Math.floor(Number(tripcount)).toLocaleString()}</b>`;
            const trip = `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Toll Trip Cost:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>${formatNumberToCurrencyNoDecimal(
              Number(trip_total),
              '$'
            )} (${Count})</span>`;
            const per_hour = `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Avg Toll per Hour: <span>${formatNumberToCurrency(
              Number(avgtoll_per_hour),
              '$'
            )}/hour</span>`;
            const per_mile = `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Avg Toll Per Mile:&nbsp;&nbsp;<span>${formatNumberToCurrency(
              Number(avgtoll_per_mile),
              '$'
            )}/mile</span>`;

            // Get the dataset color for the legend box
            const colors = context.tooltip.labelColors[0];
            const legendBox = `
        <span style="display:inline-block; width:10px; height:10px; background-color:${colors.backgroundColor}; border:1px solid ${colors.borderColor}; margin-right:5px;"></span>
      `;

            // Combine label, dataset label, and the rest of the data
            const innerHtml = `
        <table style="text-align:left">
          <tbody>
            <tr><td>${formatMonth(label)}</td></tr> <!-- Add legend box and dataset label -->
            <tr><td>${legendBox}${datasetLabel}</td></tr> <!-- Add legend box and dataset label -->
            <tr><td>${trip}</td></tr> <!-- Toll Trip Cost -->
            <tr><td>${per_hour}</td></tr> <!-- Avg Toll per Hour -->
            <tr><td>${per_mile}</td></tr> <!-- Avg Toll per Mile -->
          </tbody>
        </table>
      `;

            // Null check for tableRoot before updating innerHTML
            let tableRoot = tooltipEl.querySelector('table');
            if (tableRoot) {
              tableRoot.innerHTML = innerHtml;
            }
          }

          // Position tooltip
          const position = context.chart.canvas.getBoundingClientRect();
          tooltipEl.style.opacity = '1';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.font = '12px Arial';
          tooltipEl.style.padding = '10px';
          tooltipEl.style.backgroundColor = 'rgba(0, 0, 0,0.9)';
        }
      }
    },
    scales: {
      y: {
        stacked: false,
        title: {
          display: true,
          text: 'Trips (Count)',
          font: {
            weight: 'bold'
          }
        },
        min: 0,
        ticks: {
          display: true,
          font: {
            weight: 'bold'
          },
          callback: (value: string | number) => {
            if (typeof value === 'number') {
              if (value >= 100000) {
                return `${(value / 1000).toLocaleString()}K`;
              }
              return `${value.toLocaleString()}`;
            }
            return value;
          }
        }
      },
      x: {
        stacked: false,
        grid: {
          display: false
        },
        ticks: {
          font: {
            weight: 'bold'
          }
        }
      }
    }
  });
  useEffect(() => {
    let newHeight = '73rem'; // Default height for LG
    switch (true) {
      case isLg:
        newHeight = '73rem';
        break;
      case isXl:
        newHeight = '73rem';
        break;
      case isMd:
        newHeight = '73rem';
        break;
      case isSm:
        newHeight = '80rem';
        break;
      default:
        newHeight = '80rem';
        break;
    }
    setCardHeight(newHeight);
  }, [isXs, isSm, isMd, isLg, isXl]);
  useEffect(() => {
    let newPaddingRight = 20;
    let newPaddingBottom = 20;

    switch (true) {
      case isXs:
      case isSm:
        newPaddingRight = 0;
        newPaddingBottom = 0;
        break;
      case !isMd2 && isMd:
        newPaddingRight = 20;
        newPaddingBottom = 20;
        break;
      case isMd2:
        newPaddingRight = 20;
        newPaddingBottom = 20;
        break;
      case isLg:
        newPaddingRight = 20;
        break;
      case isXl:
        newPaddingRight = 20;
        break;
      default:
        newPaddingRight = 20;
        break;
    }

    setChartOptions(prevOptions => ({
      ...prevOptions,
      layout: {
        ...prevOptions.layout,
        padding: {
          right: newPaddingRight,
          bottom: newPaddingBottom
        }
      }
    }));
  }, [isXs, isSm, isMd, isMd2, isLg, isXl]);
  return (
    <>
      {selectedMonth.length === 0 && (
        <MaterialCard
          title="Trip Insights"
          minHeight="60vw"
          cardHeight="100%"
          noGrid
          helpLocation={'trip_insights'}
          hasBorder
        >
          {isLoading && (
            <>
              <BarChartSkeleton />
            </>
          )}
          {!isLoading && !showError && tripInsightsData && (
            <Grid container>
              <Grid container xs={12} sm={12} md={12} lg={12} className={styles.barChart}>
                <BarChart
                  data={tripInsightsData}
                  width="100%"
                  options={chartOptions}
                  plugins={[customSymbol]}
                />
              </Grid>
            </Grid>
          )}
          {!isLoading && (showError || !tripInsightsData) && (
            <div className={styles.error}>
              Something went wrong!
              <div className={styles.retry}>
                <Button
                  size="small"
                  variant="contained"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={fetchTripInsightsData}
                  startIcon={<Repeat className={styles.icon} />}
                >
                  Retry
                </Button>
              </div>
            </div>
          )}
        </MaterialCard>
      )}
      {isLoading && (
        <MaterialCard
          cardHeight={cardHeight}
          title={titleName}
          helpLocation={helpLocation}
          hasBorder
          maxCols={6}
        >
          {[1, 2, 3, 4].map(() => (
            <Skeleton
              style={{ marginTop: '1rem' }}
              height="28rem"
              width={commonDimension.width}
              variant="rectangular"
            />
          ))}{' '}
        </MaterialCard>
      )}
      {!isLoading && !showError && tollTripCostData && (
        <MaterialCard
          cardHeight={cardHeight}
          cardWidth="100%"
          title={titleName}
          helpLocation={helpLocation}
          hasBorder
          maxCols={6}
        >
          <TollTripCost chart_data={tollTripCostData} />
          <TollTripCount chart_data={tollTripCountData} />
          <AverageTollPerHour chart_data={averageTollPerHourData} />
          <AverageTollPerMile chart_data={averageTollPerMileData} />
        </MaterialCard>
      )}
      {!isLoading && (showError || !tollTripCostData) && (
        <div className={styles.error}>
          Something went wrong!
          <div className={styles.retry}>
            <Button
              size="small"
              variant="contained"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={fetchTripInsightsData}
              startIcon={<Repeat className={styles.icon} />}
            >
              Retry
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(TripInsights);
