/* eslint-disable no-underscore-dangle */
import { useMemo, useState } from 'react';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
// import moment from 'moment-timezone';
import { Divider, Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import MaterialAccordion from '@common/ui/Mui/Accordion/MaterialAccordion';
import { ChevronRight } from '@assets/icons';
import { formatCurrency, formatPercentage } from '@pages/dashboard/common/helper';
import TreemapChart from '@common/ui/Chart/TreeChart/TreeChart';
import styles from './tbs.module.scss';
import useTollBillSummaryData from './hooks/useTollBillSummaryData';
import { ITollBillSummaryProps } from './type';

const TollBillSummary = ({ selectedMonth }: ITollBillSummaryProps) => {
  const { chartData, colorMapping, isLoading, tbsModData } = useTollBillSummaryData(selectedMonth);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isXl2 = window.innerWidth >= 1920;
  const isMd2 = window.innerWidth > 950 && window.innerWidth < 970; /** range between 950 to 970 */

  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  let marginLeftValue = '0rem';
  let marginRightValue = '0rem';

  if (isXs) marginLeftValue = '4rem';
  if (isSm) marginLeftValue = '7rem';
  if (isMd) marginLeftValue = '5rem';
  if (isLg) marginLeftValue = '7rem';
  if (isXl) marginLeftValue = '5rem';
  if (isXl2) marginLeftValue = '6rem';

  // if (isXs) marginRightValue = '4rem';
  if (isSm) marginRightValue = '1rem';
  if (isMd) marginRightValue = '1rem';
  if (isLg) marginRightValue = '1rem';
  if (isXl) marginRightValue = '0rem';
  if (isXl2) marginRightValue = '2rem';

  const treeMapOptions: AnyObject = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        autoPadding: true
      },
      plugins: {
        legend: {
          display: false
        },
        datalabels: {
          display: false
        },
        tooltip: {
          displayColors: true,
          callbacks: {
            title(items: { raw: { _data: { name: string; value: number } } }[]) {
              const label = items[0]?.raw._data.name || '';
              const formattedValue = formatCurrency(items[0]?.raw._data.value.toString());
              const combinedLabel = `${label}: ${formattedValue}`;
              return combinedLabel;
            },
            label() {
              return '';
            }
          }
        }
      },
      elements: {
        treemap: {
          labels: {
            padding: -0.1
          }
        }
      }
    };
  }, []);
  // const showNoDateForAudit = false;

  const [accordionOpen, setAccordionOpen] = useState(true);

  return (
    <div className={styles.container}>
      <MaterialCard
        title="Toll Bill Overview"
        cardHeight="100%"
        noGrid
        helpLocation="toll_bill_overview"
        hasBorder
        // subTitle={
        //   <>
        //     {isLoading && (
        //       <>
        //         <Skeleton height="1rem" width="25%" variant="rectangular" />
        //         <Skeleton
        //           style={{ marginTop: '1rem' }}
        //           height="1rem"
        //           width="50%"
        //           variant="rectangular"
        //         />
        //       </>
        //     )}
        //     {!isLoading && selectedMonth.length === 0 && (
        //       <>
        //         <p className={styles['updated-by']}>
        //           Last updated {moment.utc(tbsModData?.tollBillDate.updated).format('LL')}
        //         </p>
        //         <p>
        //           Showing{' '}
        //           {showNoDateForAudit ? (
        //             <>{moment.utc(tbsModData?.tollBillDate.from).format('MMMM, YYYY')}</>
        //           ) : (
        //             <>
        //               {moment.utc(tbsModData?.tollBillDate.from).format('LL')} to{' '}
        //               {moment.utc(tbsModData?.tollBillDate.to).format('LL')}
        //             </>
        //           )}
        //         </p>
        //       </>
        //     )}
        //   </>
        // }
      >
        <Grid container>
          <Grid xs={12} xl={6} sm={12} md={6} lg={6} textAlign="start">
            {isLoading && (
              <Skeleton style={{ marginTop: '1rem' }} height="30rem" variant="rectangular" />
            )}
            {!isLoading && (
              <TreemapChart
                data={chartData}
                options={treeMapOptions}
                hasAmount
                colorMap={colorMapping}
              />
            )}
          </Grid>
          <Grid
            className={`${
              !accordionOpen && !isSm ? styles['closed-accordion'] : styles['open-accordion']
            }`}
            xs={12}
            xl={6}
            sm={12}
            md={6}
            lg={6}
          >
            <MaterialAccordion
              height="5rem"
              elevation={0}
              onClick={() => !isLoading && setAccordionOpen(!accordionOpen)}
              isOpen={accordionOpen}
              showTopBorder={false}
              title={<p className={styles['acc-title']}>Expenses</p>}
              summary={
                <>
                  {isLoading && <Skeleton height="2rem" width="55%" variant="rectangular" />}
                  {!isLoading && tbsModData && (
                    <div className={styles['acc-summary']}>
                      <span className={styles['expense-total']}>
                        {formatCurrency(tbsModData.expense.amount)}
                      </span>
                      <span
                        className={styles['expense-percent']}
                        style={{ marginLeft: marginLeftValue, marginRight: marginRightValue }}
                      >
                        {formatPercentage(tbsModData.expense.percent)}
                      </span>
                    </div>
                  )}
                </>
              }
              iconPosition="start"
              customIcon={<ChevronRight />}
            >
              {isLoading && (
                <Skeleton style={{ marginTop: '1rem' }} height="5rem" variant="rectangular" />
              )}
              {!isLoading && tbsModData && (
                <Grid container spacing={1} className={styles['sub-types']}>
                  {tbsModData?.subTypes.map(category => (
                    <>
                      <Grid
                        item
                        xs={7.8}
                        sm={8.1}
                        md={7}
                        lg={8}
                        xl={9}
                        className={styles['label-grid']}
                      >
                        <div
                          className={styles['legend-box']}
                          style={{
                            backgroundColor: colorMapping[category.label]
                          }}
                        />
                        {category.label}
                      </Grid>
                      <Grid
                        textAlign="start"
                        className={styles.amount}
                        item
                        xs={2.05}
                        sm={1.95}
                        md={2.5}
                        lg={2}
                        xl={1.5}
                      >
                        {formatCurrency(category.amount)}
                      </Grid>
                      <Grid
                        textAlign="start"
                        className={styles.percent}
                        item
                        xs={2.05}
                        sm={1.95}
                        md={2.5}
                        lg={2}
                        xl={1.5}
                      >
                        {formatPercentage(category.percent)}
                      </Grid>
                    </>
                  ))}
                </Grid>
              )}
            </MaterialAccordion>
            {isLoading && (
              <Skeleton
                style={{ marginTop: '1rem', marginLeft: '1rem' }}
                height="15rem"
                variant="rectangular"
              />
            )}
            {!isLoading && tbsModData && (
              <>
                <Grid
                  container
                  style={isMd2 ? { marginLeft: '3rem' } : { marginLeft: '6rem' }}
                  className={styles.correction}
                >
                  <Grid
                    className={styles.label}
                    // item
                    xs={7.8}
                    sm={8.1}
                    md={7}
                    lg={8}
                    xl={isXl2 ? 8.9 : 8.9}
                  >
                    Corrections & Adjustments
                  </Grid>
                  <Grid
                    className={styles.amount}
                    // item
                    textAlign="start"
                    xs={2.1}
                    sm={1.95}
                    md={2.5}
                    lg={2}
                    xl={isXl2 ? 1.6 : 1.5}
                  >
                    {`$(${Number(tbsModData.correction.amount).toFixed(2)})`}
                  </Grid>
                  <Grid
                    textAlign="start"
                    item
                    xs={2.1}
                    sm={1.95}
                    md={2.5}
                    lg={2}
                    xl={isXl2 ? 1.5 : 1.6}
                  >
                    {formatPercentage(tbsModData.correction.percent)}
                  </Grid>
                </Grid>
                <Divider
                  style={isMd2 ? { marginLeft: '3rem' } : { marginLeft: '3rem' }}
                  className={styles.divider}
                />
                <div
                  style={isMd2 ? { marginLeft: '3rem' } : { marginLeft: '6rem' }}
                  className={styles.breakout}
                >
                  <Grid container className={styles['actual-toll-bill']}>
                    <Grid
                      textAlign="start"
                      xs={8.9}
                      sm={8.9}
                      md={8}
                      lg={8.9}
                      xl={isXl2 ? 9.7 : 9.7}
                    >
                      Invoiced Toll Bill
                    </Grid>
                    <Grid
                      textAlign="start"
                      xs={3.1}
                      sm={3.1}
                      md={4}
                      lg={3.1}
                      xl={isXl2 ? 2.3 : 1.5}
                    >
                      {formatCurrency(tbsModData.actualTollBill.amount)}
                    </Grid>
                  </Grid>
                  <Grid container className={styles['managed-toll-bill']}>
                    <Grid
                      textAlign="start"
                      xs={8.9}
                      sm={8.9}
                      md={8}
                      lg={8.9}
                      xl={isXl2 ? 9.7 : 9.7}
                    >
                      tollogic™ Tolls
                    </Grid>
                    <Grid
                      textAlign="start"
                      xs={3.1}
                      sm={3.1}
                      md={4}
                      lg={3.1}
                      xl={isXl2 ? 2.3 : 1.5}
                    >
                      {formatCurrency(tbsModData.managedTollBill.amount)}
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </MaterialCard>
    </div>
  );
};

export default TollBillSummary;
